import React from 'react';
import List from '@mui/material/List';
import { Link } from 'react-router-dom';
import { signOut as AmpSignOut } from 'aws-amplify/auth';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';
import { CSVLink } from 'react-csv';
import SelectorGroup from './SelectorGroup';
import DatePicker from './DatePicker';
import useData from '../../hooks/useData';

const dayjs = require('dayjs');

const drawerWidth = 200;

const StyledDiv = styled('div')(({ theme }) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function LeftNav() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { exportData } = useData();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = () => {
    AmpSignOut()
      .then(() => {
        // Redirect or reload the page after sign-out
        window.location.reload(false);
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };

  return (
    <div className="flex flex-col h-screen justify-start divide-y-1 divide-gray-400">
      <div className="px-2 py-1">
        <Link to="/">
          <img alt="" src={`${process.env.PUBLIC_URL}gwtt.png`} />
        </Link>
      </div>
      <div>
        <List>
          <Link to="/">
            <div className="flex flex-row items-center py-5 pr-2">
              <i className="fas fa-columns fa-2x center px-5 fill-current text-gray-500" />
              <p className="">Utilization</p>
            </div>
          </Link>
          <Link to="/status">
            <div className="flex flex-row items-center py-5 pr-2">
              <i className="fas fa-info-circle fa-2x center px-5 fill-current text-gray-500" />
              <p className="">Equipment Status</p>
            </div>
          </Link>
          <Link to="/alerts">
            <div className="flex flex-row items-center py-5 pr-2">
              <i className="fas fa-exclamation-triangle fa-2x center px-5 fill-current text-gray-500" />
              <p className="">Alerts</p>
            </div>
          </Link>
          <Link to="/usermanagement">
            <div className="flex flex-row items-center py-5 pr-2">
              <i className="fas fa-users fa-2x center px-5 fill-current text-gray-500" />
              <p className="break-normal">User Management</p>
            </div>
          </Link>
        </List>
      </div>
      <div>
        <button
          className="text-gray-900 rounded items-center flex flex-row py-5 pr-2 mb-auto"
          onClick={handleClick}
        >
          <i
            className="fas fa-filter fa-2x center px-5 fill-current"
            style={{ color: '#A6610C' }}
          />
          <p className="break-normal">Filters</p>
        </button>
        <CSVLink data={exportData} filename={`export-${dayjs().format()}.csv`}>
          <div className="text-gray-900 rounded items-center flex flex-row py-5 pr-2 mb-auto">
            <i
              className="fas fa-file-export fa-2x center px-5 fill-current"
              style={{ color: '#A6610C' }}
            />
            <p className="">Export</p>
          </div>
        </CSVLink>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
        >
          <StyledDiv>
            <div className="text-gray-900 rounded items-center flex flex-col py-7 px-5 mb-auto space-y-10">
              <div className="flex-col">
                <div className="font-semibold text-center">Selectors</div>
                <div>
                  <SelectorGroup />
                </div>
              </div>
              <div className="flex-col space-y-2 py-2">
                <div className="font-semibold text-center">Date Filters</div>
                <div>
                  <DatePicker />
                </div>
              </div>
            </div>
          </StyledDiv>
        </Popover>
      </div>

      <div className="flex items-end fill-current h-screen ">
        <div className="divide-gray-400" />
        <button
          className="inset-x-0 bottom-0  text-gray-900 pt-2 px-4 inline-flex items-center"
          onClick={signOut}
        >
          <i className="fas fa-sign-out-alt fa-2x center px-5 fill-current text-gray-500" />
          <p className="break-normal">Sign Out</p>
        </button>
      </div>
    </div>
  );
}
