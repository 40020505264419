import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import { EnhancedTableHead } from '../pure/EnhancedTableHead';
import useData from '../../hooks/useData';

const StyledDiv = styled('div')(({ theme }) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  container: {
    maxHeight: '100%',
  },
  paper: {
    width: '100%',
    height: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  pagination: {
    bottom: 0,
    position: 'fixed',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'utilization') {
    if (100 * (b.days_rented / b.total_days) < 100 * (a.days_rented / a.total_days)) {
      return -1;
    }
    if (100 * (b.days_rented / b.total_days) > 100 * (a.days_rented / a.total_days)) {
      return 1;
    }
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const tableHeader = [
  { id: 'item_type', numeric: false, disablePadding: false, label: 'Item Type' },
  { id: 'class', numeric: false, disablePadding: false, label: 'Class' },
  { id: 'description', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'gec_no', numeric: false, disablePadding: false, label: 'GEC Number' },
  { id: 'gwtt_no', numeric: false, disablePadding: false, label: 'GWTT Number' },
  { id: 'total_items', numeric: true, disablePadding: false, label: 'Total Items' },
  { id: 'total_rented', numeric: true, disablePadding: false, label: 'Total Rented' },
  { id: 'days_rented', numeric: true, disablePadding: false, label: 'Days Rented' },
  { id: 'total_days', numeric: true, disablePadding: false, label: 'Total Days' },
  { id: 'utilization', numeric: true, disablePadding: false, label: 'Utilzation' },
];

export default function DisplayTable() {
  const { aggregatedData } = useData();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('days_rented');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (aggregatedData) {
    return (
      <div className="flex flex-col h-screen w-screen">
        <div className="h-5/6 mt-20 mx-6 mb-6 ">
          <TableContainer className="flex shrink flex-col h-full justify-start">
            <Table stickyHeader>
              <EnhancedTableHead
                headers={tableHeader}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />

              <TableBody>
                {stableSort(aggregatedData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow
                      key={row.id ? row.id : index}
                      style={index % 2 ? { background: '#ededed' } : { background: 'white' }}
                    >
                      <TableCell component="th" scope="row">
                        {row.item_type}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.class}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.description}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.gec_no}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.gwtt_no}
                      </TableCell>
                      <TableCell align="right" component="th" scope="row">
                        {row.total_items}
                      </TableCell>
                      <TableCell align="right" component="th" scope="row">
                        {row.total_rented}
                      </TableCell>
                      <TableCell align="right" component="th" scope="row">
                        {row.days_rented}
                      </TableCell>
                      <TableCell align="right" component="th" scope="row">
                        {row.total_days}
                      </TableCell>
                      <TableCell align="right" component="th" scope="row">
                        {(100 * (row.days_rented / row.total_days)).toFixed(2)}%
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="flex justify-start ">
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={aggregatedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    );
  }
  return <div />;
}
