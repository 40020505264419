import { React, useEffect, useState } from 'react';
import PieChartCard from '../pure/PieChartCard';
import useData from '../../hooks/useData';

export default function EquipmentStatus() {
  const { aggregatedData } = useData();
  const [displayData, setDisplayData] = useState([]);
  useEffect(() => {
    const data = [];
    aggregatedData.forEach((item) => {
      console.log(item);
      let title = item.item_type;
      if (item.class !== '') {
        title = item.class;
      }
      if (item.description !== '') {
        title = item.description;
      }
      if (item.gec_no !== '') {
        title = item.gec_no;
      }
      if (item.gwtt_no !== '') {
        title = item.gwtt_no;
      }
      data.push({
        title,
        data: [
          { name: 'Rented', value: item.rented.length },
          { name: 'On Hold', value: item.on_hold.length },
          { name: 'In Yard', value: item.in_yard.length },
        ],
      });
    });
    setDisplayData(data);
  }, [aggregatedData]);

  return (
    <div className="flex flex-row w-auto flex-wrap p-4 ">
      {displayData.map((item) => (
        <PieChartCard
          key={item?.title}
          title={item?.title}
          data={item?.data}
          colors={['red', 'blue', 'green']}
        />
      ))}
    </div>
  );
}
