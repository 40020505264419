import { React } from 'react';
import { PieChart, Pie, Cell, Legend } from 'recharts';
import Paper from '@mui/material/Paper';

export default function PieChartCard(props) {
  const renderColorfulLegendText = (value, entry) => {
    const { color, payload } = entry;

    return <span style={{ color }}>{`${value} - ${payload.value}`}</span>;
  };

  return (
    <div className="p-3">
      <div className="text-center text-lg ">{props.title}</div>
      <div>
        <PieChart width={386} height={150}>
          <Legend
            layout="vertical"
            height={36}
            align="right"
            iconType="square"
            verticalAlign="middle"
            formatter={renderColorfulLegendText}
          />
          <Pie
            data={props.data}
            cx="35%"
            cy="50%"
            outerRadius={60}
            fill="#8884d8"
            dataKey="value"
            colors
          >
            {props.data.map((entry, index) => (
              <Cell fill={props.colors[index % props.colors.length]} />
            ))}
          </Pie>
        </PieChart>
      </div>
    </div>
  );
}
