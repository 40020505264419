import React from 'react';
import Grid from '@mui/material/Grid';
import { DatePicker as MuiDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import useData from '../../hooks/useData';

export default function DatePicker() {
  const { startDate, endDate, setStartDate, setEndDate } = useData();

  const handleStartChange = (date) => {
    setStartDate(dayjs(date));
  };

  const handleEndChange = (date) => {
    setEndDate(dayjs(date));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="shrink space-x-5">
        <Grid container justifyContent="space-around">
          <MuiDatePicker
            label="Start Date"
            inputFormat="MM/dd/yyyy"
            value={startDate.toDate()}
            onChange={handleStartChange}
            textField={(params) => <TextField {...params} />}
          />
          <MuiDatePicker
            label="End Date"
            inputFormat="MM/dd/yyyy"
            value={endDate.toDate()}
            onChange={handleEndChange}
            textField={(params) => <TextField {...params} />}
          />
        </Grid>
      </div>
    </LocalizationProvider>
  );
}
