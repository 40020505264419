import React from 'react';
import { createRoot } from 'react-dom/client';
import { Amplify } from 'aws-amplify';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import App from './components/App';
import amplifyconfig from './amplifyconfiguration.json';
import './index.css';
import '@aws-amplify/ui-react/styles.css';

const theme = createTheme();

Amplify.configure(amplifyconfig);
const existingConfig = Amplify.getConfig();
Amplify.configure({
  ...existingConfig,
  API: {
    ...existingConfig.API,
    REST: {
      ...existingConfig.API.REST,
      utilizationAPI: {
        endpoint: 'https://rgrdo9zglc.execute-api.us-east-1.amazonaws.com/dev',
        region: 'us-east-1',
      },
    },
  },
});
const container = document.querySelector('#root');
const root = createRoot(container);

root.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
);
