import { React } from 'react';
import { styled } from '@mui/material/styles';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const StyledDiv = styled('div')(({ theme }) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function Selector({ id, label, menuItems, onChange, selected }) {
  return (
    <StyledDiv>
      <FormControl>
        <Autocomplete
          style={{ width: '450px', height: '30px' }}
          id={id}
          fullWidth
          options={menuItems}
          getOptionLabel={(option) => option.toString()}
          value={selected}
          renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
          onChange={onChange}
          multiple
        />
      </FormControl>
    </StyledDiv>
  );
}
