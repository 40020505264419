import { React, useState, useEffect } from 'react';
import ReactCardFlip from 'react-card-flip';

export default function AlertCard({
  icon,
  item_type,
  item_class,
  description,
  color,
  utilizationTotal,
  available,
  in_yard,
  on_hold,
  rented,
}) {
  const [iconJSX, setIconJSX] = useState();
  const [splitDescription, setSplitDescription] = useState([description]);
  const [isFlipped, setIsFlipped] = useState(false);

  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };

  useEffect(() => {
    if (icon === 'warning') {
      setIconJSX(
        <i
          className="fas fa-2x fa-exclamation-triangle align-middle pr-4 pl-2 pd-2 pu-2"
          style={{ color: '#E10600' }}
        />,
      );
    } else {
      setIconJSX(
        <i
          className="fas fa-2x fa-exclamation-circle align-middle pr-4 pl-2 pd-2 pu-2"
          style={{ color: '#CCCC00' }}
        />,
      );
    }
  }, [icon, color]);

  useEffect(() => {
    if (description.length > 39) {
      let middle = Math.floor(description.length / 2);
      const before = description.lastIndexOf(' ', middle);
      const after = description.indexOf(' ', middle + 1);
      if (middle - before < after - middle) {
        middle = before;
      } else {
        middle = after;
      }
      const split = [];
      split.push(description.substr(0, middle));
      split.push(description.substr(middle + 1));
      setSplitDescription(split);
    } else {
      setSplitDescription([description]);
    }
  }, [description]);

  return (
    <div className="shadow-md w-96 m-6 divide-solid divide-grey-900 divide-y-2">
      <div className="relative flex items-center justify-start">
        {iconJSX}
        <div className="flex-col text-md font-medium text-left pr-2 ">
          <p> {item_type}</p>
          <p> {item_class}</p>
          <p> {description}</p>
        </div>
        <i className="fas fa-md fa-info-circle absolute top-1 right-1" onClick={handleClick} />
      </div>
      <ReactCardFlip isFlipped={isFlipped} fliDirection="horizontal">
        <div className="flex justify-evenly px-5 py-2">
          <div className="items-center justify-center text-5xl pr-2 font-bold">
            <p>{available}</p>
            <p className="text-xs text-secondary text-right">Available</p>
          </div>
          <div className="items-center justify-center text-5xl pr-2 font-bold">
            <p>{utilizationTotal}</p>
            <p className="text-xs text-secondary text-right">Utilization</p>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-2">
          <div className="flex-col justify-evenly px-2 py-2">
            <p> In Yard</p>
            <div>
              {in_yard?.map((item) => (
                <p className="justify-center">{item}</p>
              ))}
            </div>
          </div>
          <div className="flex-col justify-evenly px-2 py-2">
            <p> On Hold</p>
            <div>
              {on_hold?.map((item) => (
                <p>{item}</p>
              ))}
            </div>
          </div>
          <div className="flex-col justify-evenly px-2 py-2">
            <p> Rented</p>
            <div>
              {rented?.map((item) => (
                <p>{item}</p>
              ))}
            </div>
          </div>
        </div>
      </ReactCardFlip>
    </div>
  );
}
