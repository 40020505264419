import { useContext } from 'react';
import DataContext from '../context/data';

const useData = () => {
  const context = useContext(DataContext.Context);
  if (context === undefined) {
    throw new Error('useData must be used within a DataContextProvider');
  }
  return context;
};

export default useData;
