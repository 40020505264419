import React, { useEffect, useState } from 'react';
import apiFetch from '../functions/api';
import { aggregationLevels, aggregateData, applyFilters } from '../functions/dataHelper';

const dayjs = require('dayjs');

const DataContext = React.createContext();

const DataContextProvider = ({ children }) => {
  const [initialData, setInitialData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [aggregatedData, setAggregatedData] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [aggregationLevel, setAggregationLevel] = useState(aggregationLevels.ITEMTYPE);
  const [itemTypeFilter, setItemTypeFilter] = useState([]);
  const [classFilter, setClassFilter] = useState([]);
  const [descriptionFilter, setDescriptionFilter] = useState([]);
  const [gecFilter, setGECFilter] = useState([]);
  const [gwttFilter, setGWTTFilter] = useState([]);
  const [startDate, setStartDate] = useState(dayjs().subtract('1', 'year'));
  const [endDate, setEndDate] = useState(dayjs());

  const fetchData = async () => {
    const startFormatted = startDate.format('YYYY/MM/DD').toString();
    const endFormatted = endDate.format('YYYY/MM/DD').toString();
    try {
      const data = await apiFetch(startFormatted, endFormatted);
      setInitialData(data);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  useEffect(() => {
    console.log(
      'fetching data with start date: ',
      startDate.format('YYYY/MM/DD').toString(),
      ' and end date: ',
      endDate.format('YYYY/MM/DD').toString(),
    );
    fetchData();
  }, []);

  useEffect(() => {
    console.log(
      'fetching data with start date: ',
      startDate.format('YYYY/MM/DD').toString(),
      ' and end date: ',
      endDate.format('YYYY/MM/DD').toString(),
    );
    fetchData();
  }, [startDate, endDate]);

  useEffect(() => {
    const data = aggregatedData;
    if (data && data.length > 0) {
      data.forEach((datum) => {
        delete datum.in_yard;
        delete datum.on_hold;
        delete datum.rented;
        datum.utilization = ((datum.days_rented / (1.0 * datum.total_days)) * 100)
          .toFixed(2)
          .toString()
          .concat('%');
      });

      setExportData(data);
    }
  }, [aggregatedData]);

  useEffect(() => {
    const data = aggregateData(displayData, aggregationLevel);
    setAggregatedData(data);
  }, [displayData, aggregationLevel]);

  useEffect(() => {
    const { filteredData, aggregation } = applyFilters(
      initialData,
      itemTypeFilter,
      classFilter,
      descriptionFilter,
      gecFilter,
      gwttFilter,
    );
    setDisplayData(filteredData);

    setAggregationLevel(aggregation);
  }, [initialData]);

  return (
    <DataContext.Provider
      value={{
        initialData,
        displayData,
        aggregationLevel,
        aggregatedData,
        exportData,
        setDisplayData,
        setAggregationLevel,
        setExportData,
        setEndDate,
        setStartDate,
        startDate,
        endDate,
        itemTypeFilter,
        classFilter,
        descriptionFilter,
        gecFilter,
        gwttFilter,
        setItemTypeFilter,
        setClassFilter,
        setDescriptionFilter,
        setGECFilter,
        setGWTTFilter,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default {
  Context: DataContext,
  Provider: DataContextProvider,
};
