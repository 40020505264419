import { get } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';

const apiName = 'utilizationAPI';
const pathTemplate = '/utilization?start={start}&end={end}';

const apiFetch = async (start, end) => {
  try {
    const token = (await fetchAuthSession()).tokens?.idToken?.toString();

    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const path = pathTemplate.replace('{start}', start).replace('{end}', end);
    const restOperation = get({ apiName, path, options });
    const response = await restOperation.response;

    if (response.statusCode !== 200) {
      throw new Error('Error fetching data');
    }
    const data = await response.body.json();

    return data.data;
  } catch (error) {
    // Handle errors here
    console.error('Error fetching data: ', error);
    throw error;
  }
};

export default apiFetch;
