import React, { useState, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import {
  CognitoIdentityProviderClient,
  ListUsersCommand,
  AdminDeleteUserCommand,
  AdminCreateUserCommand,
} from '@aws-sdk/client-cognito-identity-provider';

import { useForm } from 'react-hook-form';

const client = new CognitoIdentityProviderClient({
  region: 'us-east-1',
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  },
});

const command = new ListUsersCommand({
  UserPoolId: process.env.REACT_APP_AWS_USER_POOL,
  AttributesToGet: ['name', 'email'],
});

const refreshPage = () => {
  window.location.reload();
};

export default function userManagement() {
  const { register, handleSubmit, watch, errors } = useForm();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const data = client.send(command).then((data) => {
      for (const [key, value] of Object.entries(data.Users)) {
        const held = [];
        value.Attributes.forEach((el) => {
          held.push(el.Value);
        });
        if (held.length == 1) {
          held.unshift('~Verification Pending~');
        }
        setUsers((oldArray) => [...oldArray, held]);
      }
    });
  }, []);

  const [addUser, setAddUser] = useState(false);

  const testFormData = { userName: null, email: null, admin: null };

  const handleDeleteUser = (row) => {
    const command = new AdminDeleteUserCommand({
      UserPoolId: process.env.REACT_APP_AWS_USER_POOL,
      Username: row[1],
    });
    client.send(command);
    alert('user deleted');
    setUsers(users.filter((e) => e !== row));
  };

  const handleAddUser = (data) => {
    const command = new AdminCreateUserCommand({
      UserPoolId: process.env.REACT_APP_AWS_USER_POOL,
      Username: data.email,
    });
    client.send(command);
    setUsers((oldArray) => [...oldArray, ['~Verification Pending~', data.email]]);
    alert('User added');
    setAddUser(false);
  };

  const handleDeleteVerify = (row) => {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to delete this user?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleDeleteUser(row),
        },
        {
          label: 'No',
          onClick: () => alert('User not deleted'),
        },
      ],
    });
  };

  return (
    <div className="p-6 pr-64 h-screen w-screen flex flex-col flex-nowrap gap-2 pt-24 place-content-start">
      <div className="border-b-1 border-gray-400 grid grid-cols-3 flex-nowrap overflow-y-auto gap-2 place-content-start">
        <p>Name</p>
        <p>Email</p>
      </div>
      <div>
        <div className="flex flex-col flex-nowrap overflow-y-auto gap-2 place-content-start">
          {users.map((row) => (
            <div
              key={row[0]}
              className="grid grid-cols-3 gap-3 justify-start items-center justify-items-start"
            >
              <p>{row[0]}</p>
              <p>{row[1]}</p>
              <button
                className="font-semibold bg-gray-300 text-gray-600 py-2 px-4 rounded justify-self-end"
                onClick={() => handleDeleteVerify(row)}
              >
                <p>Delete User</p>
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className="pt-5 grid grid-cols-3 gap-3">
        <p />
        <p />
        <button
          className="font-semibold bg-secondary text-gray-200 py-2 px-4 rounded justify-self-end "
          onClick={() => {
            setAddUser(!addUser);
          }}
        >
          <p className="px-4 py-2"> Add User</p>
        </button>
      </div>
      {addUser ? (
        <div className="mt-32">
          <form onSubmit={handleSubmit(handleAddUser)}>
            <div className="flex flex-row pb-2 mx-10 items-center">
              <p className="pr-2 ">Invite User</p>
              <input
                className="mr-6 self-stretch rounded "
                name="email"
                id="email"
                size="email"
                placeholder="Email address"
                ref={register}
              />
              <input
                className="mx-2 font-semibold bg-secondary text-gray-200 py-2 px-4 rounded justify-self-end"
                type="submit"
              />
            </div>
          </form>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}
