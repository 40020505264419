import { React, useEffect } from 'react';
import Selector from '../pure/Selector';
import useData from '../../hooks/useData';
import { applyFilters } from '../../functions/dataHelper';

export default function SelectorGroup() {
  const {
    initialData,
    displayData,
    setDisplayData,
    setAggregationLevel,
    setItemTypeFilter,
    setClassFilter,
    setDescriptionFilter,
    setGECFilter,
    setGWTTFilter,
    itemTypeFilter,
    classFilter,
    descriptionFilter,
    gecFilter,
    gwttFilter,
  } = useData();

  const onItemTypeChange = (_, filterValue) => {
    setItemTypeFilter(filterValue);
  };
  const onClassChange = (_, filterValue) => {
    setClassFilter(filterValue);
  };
  const onDescriptionChange = (_, filterValue) => {
    setDescriptionFilter(filterValue);
  };
  const onGECNoChange = (_, filterValue) => {
    setGECFilter(filterValue);
  };
  const onGWTTNoChange = (_, filterValue) => {
    setGWTTFilter(filterValue);
  };

  useEffect(() => {
    const { filteredData, aggregation } = applyFilters(
      initialData,
      itemTypeFilter,
      classFilter,
      descriptionFilter,
      gecFilter,
      gwttFilter,
    );
    setDisplayData(filteredData);

    setAggregationLevel(aggregation);
  }, [itemTypeFilter, classFilter, descriptionFilter, gecFilter, gwttFilter, initialData]);

  return (
    // <div className="grid grid-flow-row grid-row-1 space-y-5">
    <div className="flex-col space-y-9">
      <Selector
        id="itemType"
        label="Item Type"
        menuItems={[...new Set(displayData?.map((x) => x.item_type))].filter((x) => x).sort()}
        onChange={onItemTypeChange}
        selected={itemTypeFilter}
      />
      <Selector
        id="class"
        label="Class"
        menuItems={[...new Set(displayData?.map((x) => x.class))].filter((x) => x).sort()}
        onChange={onClassChange}
        selected={classFilter}
      />
      <Selector
        id="description"
        label="Description"
        menuItems={[...new Set(displayData?.map((x) => x.description))].filter((x) => x).sort()}
        onChange={onDescriptionChange}
        selected={descriptionFilter}
      />
      <Selector
        id="gecNo"
        label="GEC No"
        menuItems={[...new Set(displayData?.map((x) => x.gec_no))].filter((x) => x).sort()}
        onChange={onGECNoChange}
        selected={gecFilter}
      />
      <Selector
        id="gwttNo"
        label="GWTT No"
        menuItems={[...new Set(displayData?.map((x) => x.gwtt_no))].filter((x) => x).sort()}
        onChange={onGWTTNoChange}
        selected={gwttFilter}
      />
    </div>
  );
}
