import { React, useEffect, useState } from 'react';
import AlertCard from '../pure/AlertCard';
import useData from '../../hooks/useData';
import { aggregationLevels, aggregateData } from '../../functions/dataHelper';

export default function UtilizationAlerts() {
  const { displayData } = useData();
  const [warnings, setWarnings] = useState([]);
  const [infos, setInfos] = useState([]);
  const [aggregatedData, setAggregatedData] = useState([]);

  useEffect(() => {
    setAggregatedData(aggregateData(displayData, aggregationLevels.DESCRIPTION));
  }, [displayData]);

  useEffect(() => {
    let dataE = [];
    const warningsE = aggregatedData.filter(
      (item) =>
        item.in_yard.length <=
        0.1 * (item.rented.length + item.on_hold.length + item.in_yard.length),
    );
    warningsE.forEach((item) => {
      dataE.push({
        item_type: item.item_type,
        item_class: item.class,
        description: item.description,
        icon: 'warning',
        color: 'red',
        utilization: `${(100 * (item.days_rented / item.total_days)).toFixed(0)}%`,
        available: `${(
          100 *
          (item.in_yard.length / (item.rented.length + item.on_hold.length + item.in_yard.length))
        ).toFixed(0)}%`,
        in_yard: item.in_yard,
        on_hold: item.on_hold,
        rented: item.rented,
        days_rented: item.days_rented,
      });
    });
    setWarnings(dataE.sort((a, b) => b.days_rented - a.days_rented));

    dataE = [];
    const infoE = aggregatedData.filter(
      (item) =>
        item.in_yard.length <
          0.333 * (item.rented.length + item.on_hold.length + item.in_yard.length) &&
        item.in_yard.length >
          0.1 * (item.rented.length + item.on_hold.length + item.in_yard.length),
    );

    infoE.forEach((item) => {
      dataE.push({
        item_type: item.item_type,
        item_class: item.class,
        description: item.description,
        icon: 'icon',
        color: 'green',
        utilization: `${(100 * (item.days_rented / item.total_days)).toFixed(0)}%`,
        available: `${(
          100 *
          (item.in_yard.length / (item.rented.length + item.on_hold.length + item.in_yard.length))
        ).toFixed(0)}%`,
        in_yard: item.in_yard,
        on_hold: item.on_hold,
        rented: item.rented,
        days_rented: item.days_rented,
      });
    });
    setInfos(dataE.sort((a, b) => b.days_rented - a.days_rented));
  }, [aggregatedData]);

  return (
    <div className="h-screen overflow-y-auto p-7 flex flex-col shrink grow">
      <p className="text-lg font-semibold"> Warnings </p>
      <div className="h-3/5 overflow-y-auto shrink">
        <div className="flex flex-row flex-wrap">
          {warnings.map((item) => (
            <AlertCard
              key={`Warning-${item.item_type}-${item.item_class}-${item.description}`}
              icon={item.icon}
              item_type={item.item_type}
              item_class={item.item_class}
              description={item.description}
              color={item.color}
              utilizationTotal={item.utilization}
              available={item.available}
              rented={item.rented}
              on_hold={item.on_hold}
              in_yard={item.in_yard}
            />
          ))}
        </div>
      </div>
      <p className="text-lg font-semibold pt-3"> Info</p>
      <div className="h-2/5 overflow-y-auto shrink">
        <div className="flex flex-row flex-wrap">
          {infos.map((item) => (
            <AlertCard
              key={`Info-${item.item_type}-${item.item_class}-${item.description}`}
              icon={item.icon}
              item_type={item.item_type}
              item_class={item.item_class}
              description={item.description}
              color={item.color}
              utilizationTotal={item.utilization}
              available={item.available}
              rented={item.rented}
              on_hold={item.on_hold}
              in_yard={item.in_yard}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
