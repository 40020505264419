import React from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import DataContext from '../context/data';
import DisplayTable from './presentation/DisplayTable';
import EquipmentStatus from './presentation/EquipmentStatus';
import UtilizationAlerts from './presentation/UtilizationAlerts';
import LeftNav from './presentation/LeftNav';
import UserManagement from './presentation/UserManagement';

export default withAuthenticator(() => (
  <DataContext.Provider>
    <Router>
      <div className="min-h-screen md:flex">
        <div className="flex-none w-56 h-screen border-r-1 border-gray-400">
          <LeftNav />
        </div>
        <Routes>
          <Route path="/" element={<DisplayTable />} />
          <Route path="/status" element={<EquipmentStatus />} />
          <Route path="/alerts" element={<UtilizationAlerts />} />
          <Route path="/usermanagement" element={<UserManagement />} />
        </Routes>
      </div>
    </Router>
  </DataContext.Provider>
));
